<template>
  <v-app-bar height="128" color="#007437">
    <div class="header_img">
      <router-link to="/" @click.native="handleLinkClick"
        ><img src="@/assets/hoozie_logo.svg"
      /></router-link>
    </div>
    <v-row
      align="center"
      justify="center"
      class="header_btns"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <v-btn
        style="background: #007437; box-shadow: none"
        @click="pushToPage('About')"
      >
        <div :class="{ header_txt: true, underlined: activeTab === 'About' }">
          Acerca de nosotros
        </div>
      </v-btn>
      <v-btn
        @click="pushToPage('FAQs')"
        style="background: #007437; box-shadow: none"
      >
        <div :class="{ header_txt: true, underlined: activeTab === 'FAQs' }">
          Preguntas frecuentes
        </div>
      </v-btn>
      <v-btn
        style="background: #007437; box-shadow: none"
        @click="pushToPage('Contact')"
      >
        <div :class="{ header_txt: true, underlined: activeTab === 'Contact' }">
          Contáctenos
        </div>
      </v-btn>
      <v-btn style="background: #007437; box-shadow: none">
        <div class="d-flex" :class="{ header_txt: true }">
          <img
            src="@/assets/mx_flag.png"
            style="width: 25px; height: 20px; margin-right: 5px"
          />
          <div>{{ "MX" }}</div>
        </div>
      </v-btn>
    </v-row>
    <v-row no-gutters justify="end" v-else>
      <v-icon color="#ffffff" @click="$emit('showNavigationDrawer')"
        >mdi-menu</v-icon
      >
    </v-row>
  </v-app-bar>
</template>

<script>
import navigationDrawer from "./components/navigationDrawer.vue";
export default {
  components: { navigationDrawer },
  name: "HeaderComponent",
  data() {
    return {
      activeTab: null,
      showNagitaionDrawer: false,
    };
  },
  mounted() {
    this.setActiveTab();
  },
  watch: {
    $route() {
      this.setActiveTab();
    },
  },
  methods: {
    pushToPage(route) {
      if (this.$route.path != "/" + route) {
        this.$router.push(`/${route}`);
        this.activeTab = route;
      }
    },
    handleLinkClick() {
      this.activeTab = null;
    },
    setActiveTab() {
      const currentRoute = this.$route.path.substr(1);
      this.activeTab = currentRoute;
    },
  },
};
</script>

<style scoped>
.header_img {
  position: absolute;
  left: calc(50% - 550px);
  top: 50%;
  transform: translateY(-50%);
}

.header_txt {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}

.underlined {
  text-decoration: underline;
}

.header_btns {
  gap: 40px;
  width: 400px;
}

@media (max-width: 1105px) {
  .header_img {
    left: calc(50% - 450px);
  }
}

@media (max-width: 930px) {
  .header_img {
    left: calc(50% - 290px);
  }

  .header_btns {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
}

@media (max-width: 600px) {
  .header_img {
    left: calc(50% - 200px);
  }
}

@media (max-width: 450px) {
  .header_img {
    left: 10px;
  }
}
</style>
