import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/Main.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('@/views/FAQs.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  {
    path: '/termsandconditions',
    name: 'termsandconditions',
    component: () => import('@/views/TermsAndConditions.vue')
  },
  {
    path: '/delete/account',
    name: 'delete_user_account',
    component: () => import('@/views/deleteUserAccount.vue')
  },
  {
    path: '/download',
    name: 'download',
    redirect: '/'
  },
  {
    path: '/downoad',
    name: 'download',
    redirect: '/'
  },
  {
    path: '/descarga',
    name: 'download',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
